.card-wishlist-list {
    display: grid;
    grid-template-columns: 1fr;
  }
  .card-wishlist-list .header {
    grid-template-columns: 2fr 1fr 1fr .5fr;
  }
  .card-wishlist-list .card {
    grid-template-columns: 2fr 1fr 1fr .5fr;
  }
  