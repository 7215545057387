.cards-list {
  display: grid;
  grid-template-columns: 1fr;
}
.cards-list .header {
  grid-template-columns: 1.15fr 1.5fr .5fr 0.35fr 0.5fr 0.5fr 0.5fr;
}
.cards-list .card {
  grid-template-columns: 1.15fr 1.5fr .5fr 0.35fr 0.5fr 0.5fr 0.5fr;
}
