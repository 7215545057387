/* .searchbar form {
    display: flex;
    align-items: end;
    max-width: 200px;
    margin: 20px auto;
    
  }
  .searchbar input {
    margin-left: 10px;
  } */

  .searchbar form {
    display: flex;
    align-items: center;
  }
  .searchbar input {
    margin-left: 10px;
  }