.lego-list {
  display: grid;
  grid-template-columns: 1fr;
}
.lego-list .header {
  grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
}
.lego-list .card {
  grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
}
